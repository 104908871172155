<template>
  <div class="relative height-container-mapbox">
    <div class="absolute left-12 bottom-12 z-30 lg:z-10 lg:left-4">
      <MapType />
    </div>
    <div class="absolute top-4 right-10 sm:right-4 z-10"><Tips /></div>
    <div class="h-full w-full lg:!h-[75vh] height-container-mapbox">
      <div id="mapbox"></div>
    </div>
  </div>
</template>
<script>
import mapbox from '@/mixins/map/mapbox'
import clusterHelpers from '@/mixins/map/clusterHelpers'

import { mapGetters } from 'vuex'
import { searchForArray } from '@/utilities/arrayOperations'

export default {
  mixins: [mapbox, clusterHelpers],
  computed: {
    ...mapGetters({
      map: 'map/map',
      historiques: 'address/getAdresseHistorique',
    }),
  },
  mounted() {
    if (this.loadMap()) {
      this.fireEventMap()
      this.loadDataToMap()
    } else {
      this.fireEventMap()
      this.map.on('load', this.loadDataToMap)
    }
  },

  methods: {
    fireEventMap() {
      this.map.on('moveend', this.findAddressesInsideCurrentPosition)
      this.map.on('render', this.updateMarkers)
      this.map.on('moveend', () =>
        this.$store.commit('address/SET_HISTORIQUE_LOADING', false),
      )
    },
    addSourceHistoriquesInMap() {
      this.markers.forEach((marker) => {
        marker.remove()
      })
      let points = this.historiques.map((historique) => {
        let point = this.$turf.point([historique.lat, historique.long])
        point.properties.id = historique.id
        return point
      })

      this.removeSourceAndLayers(...this.source_and_layers_cluster)
      if (points.length) {
        let collection_points = this.$turf.featureCollection(points)

        this.addSourceAndLayerForCluster(
          collection_points,
          this.source_and_layers_cluster,
        )
      }
      this.findAddressesInsideCurrentPosition()
      this.map.fire('render')
    },
    loadDataToMap() {
      this.$store.dispatch('address/getHistorique').then((response) => {
        this.addSourceHistoriquesInMap()
        this.fitBoundsAddresses()
      })
    },
    updateMarkers() {
      this.clusteringMarkers(this.createIconForAddress)
    },
    createIconForAddress(props, coords) {
      let historique = this.historiques.find(
        (historique) => historique.id === props.id,
      )
      let popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
      })
      const template = this.historiquePopupTemplate(
        historique.context, // title
        historique.label, // descriptions
        'Consultez', // label_link
        `historique-${historique.id}`, // id_link
      )
      var el = document.createElement('div')
      el.className = 'mapbox-marker'
      el.style.backgroundImage = 'url(/images/map/marker_1.svg)'
      el.setAttribute('id_historique', props.id)

      let popup_marker = this.addCustomMarkerWithPopupInMap(
        template,
        [historique.lat, historique.long],
        el,
        popup,
      )
      // this.current_id = historique.uuid
      popup_marker.popup.on('open', (e) => {
        document
          .querySelector(`#historique-${historique.id}`, {
            once: true,
          })
          .addEventListener('click', () => {
            this.$emit('show', historique.uuid)
          })
        this.customPopupButtonClose(e)
      })

      return popup_marker.marker
    },

    findAddressesInsideCurrentPosition() {
      if (this.historiques.length) {
        let ProgramCoords = this.historiques.map((item) => [
          item.lat,
          item.long,
        ])
        let programCoordsPolygon = this.$turf.points(ProgramCoords)
        let intersectedPts = this.$turf
          .pointsWithinPolygon(
            programCoordsPolygon,
            this.getInfosBBox().polyBBox,
          )
          .features.map((item) => item.geometry.coordinates)
        this.$store.commit(
          'address/SET_ADRESSE_HISTORIQUE_BY_BBOX',
          this.historiques.filter((prog) => {
            return searchForArray(intersectedPts, [prog.lat, prog.long])
          }),
        )
      } else {
        this.$store.commit('address/SET_ADRESSE_HISTORIQUE_BY_BBOX', [])
      }
    },
    fitBoundsAddresses() {
      if (this.historiques.length) {
        this.fitBoundsGeojsonInMap(
          this.$turf.featureCollection(
            this.historiques.map((point_coords) =>
              this.$turf.point([point_coords.lat, point_coords.long]),
            ),
          ),
          80,
        )
      }
    },
    historiquePopupTemplate(title, description, label_link, id_link) {
      return `
        <div class='flex flex-col w-full break-words bg-white sm:w-50 sm-ws:w-55 rounded-lg text-xs'>
          <div
            class='pl-4 pr-5 flex w-full bg-promy-white rounded-t-lg border-b border-promy-gray-150 h-12'
          >
            <span
              class='text-[13px] text-promy-green-350 font-bold font-main flex items-center'
            >
              ${this.truncateText(title, 23)}
            </span>
          </div>
          <div class='flex flex-col flex-1 bg-promy-gray-50 rounded-b-lg'>
            <div class='flex w-full flex-1 items-center font-bold py-4'>
              <span
                class='overflow-y-auto pl-4 pr-8 w-full mr-2 text-promy-primary'
                style='max-height:82px;'
              >
                ${description}
              </span>
            </div>
            <div class='pl-4 flex w-full pb-4'>
            <button
              class='
              bg-promy-green-350 hover:bg-promy-green-300
              border-promy-green-350
              text-white
                leading-relaxed
                font-medium
                flex
                items-center
                justify-center
                cursor-pointer
                border
                hover:shadow-lg
                focus:outline-none
                rounded-lg
                gap-x-2
                py-2
                px-3
              '
              id='${id_link}'
            >
              ${label_link}
            </button>
          </div>
        </div>
      </div>
      `
    },
  },
  beforeDestroy() {
    this.$store.commit('address/SET_ADDRESS_HISTORIQUE', [])
    this.$store.commit('address/SET_ADRESSE_HISTORIQUE_BY_BBOX', [])
    this.map.off('load', this.loadDataToMap)
    this.map.off('moveend', this.findAddressesInsideCurrentPosition)
    this.map.off('render', this.updateMarkers)
    this.markers.forEach((marker) => {
      marker.remove()
    })
  },
}
</script>
