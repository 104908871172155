export default {
  data() {
    return {
      old_radius_bbox: null,
      markers: new Map(),
    }
  },
  methods: {
    getInfosBBox() {
      let pointOfBBox = [
        this.map.getBounds()._ne.lng,
        this.map.getBounds()._ne.lat,
      ]

      let pointOfBBox2 = [
        this.map.getBounds()._sw.lng,
        this.map.getBounds()._sw.lat,
      ]

      var polyBBox = this.$turf.bboxPolygon([...pointOfBBox, ...pointOfBBox2])

      var point1 = this.$turf.point(pointOfBBox)
      var point2 = this.$turf.point(pointOfBBox2)
      var distance = this.$turf.distance(point1, point2, {
        units: 'kilometers',
      })
      let radius = distance / 2
      var midpoint = this.$turf.midpoint(point1, point2)

      let new_radius_bbox = this.$turf.circle(midpoint, radius)

      let difference = true
      if (this.old_radius_bbox) {
        try {
          difference = this.$turf.difference(
            new_radius_bbox,
            this.old_radius_bbox,
          )
        } catch (e) {
          console.log(e)
        }
      }
      if (difference) {
        this.old_radius_bbox = new_radius_bbox
      }
      return {
        isBBoxChanged: difference,
        midpointBBox: midpoint.geometry.coordinates,
        radiusBBox: radius,
        polyBBox: polyBBox,
      }
    },
    createIconForCluster(point_count, coords, clusterID) {
      var el = document.createElement('div')
      el.className = 'mapCluster'
      el.innerText = point_count
      let marker = new this.$mapboxgl.Marker(el).setLngLat(coords)
      marker.addTo(this.map)
      el.addEventListener('click', () => {
        this.map
          .getSource(this.source_and_layers_cluster[0])
          .getClusterExpansionZoom(clusterID, (err, zoom) => {
            if (err) return

            this.map.easeTo({
              center: coords,
              zoom: zoom,
            })
          })
      })
      return marker
    },
    checkMarkerOpen(marker) {
      return (
        marker.getElement().getAttribute('key') !== this.position_active.key ||
        !this.position_active.key
      )
    },
    clusteringMarkers(
      createIconForPoint,
      addListenerToMarker = () => null,
      checkMarkerOpen = () => true,
    ) {
      if (this.map.getSource(this.source_and_layers_cluster[0])) {
        if (!this.map.isSourceLoaded(this.source_and_layers_cluster[0])) return
        var features = this.map.querySourceFeatures('promy-cluster')
        const keepMarkers = []
        for (let i = 0; i < features.length; i++) {
          const coords = features[i].geometry.coordinates
          const props = features[i].properties
          const featureID = features[i].id
          const clusterID = props.cluster_id || null
          if (props.cluster && this.markers.has('promy-cluster_' + clusterID)) {
            //Cluster marker is already on screen
            keepMarkers.push('promy-cluster_' + clusterID)
          } else if (props.cluster) {
            //This feature is clustered, create an icon for it and use props.point_count for its count
            let marker = this.createIconForCluster(
              props.point_count,
              coords,
              clusterID,
            )
            keepMarkers.push('promy-cluster_' + featureID)
            this.markers.set('promy-cluster_' + clusterID, marker)
          } else if (this.markers.has(featureID)) {
            //Feature marker is already on screen
            keepMarkers.push(featureID)
          } else {
            //Feature is not clustered and has not been created, create an icon for it
            let marker = createIconForPoint(props, coords)
            if (marker) {
              if (addListenerToMarker) {
                addListenerToMarker(marker.getElement())
              }

              keepMarkers.push(featureID)
              this.markers.set(featureID, marker)
            }
          }
        }
        //Let's clean-up any old markers. Loop through all markers
        this.markers.forEach((marker, key, map) => {
          //If marker exists but is not in the keep array
          if (keepMarkers.indexOf(key) === -1 && checkMarkerOpen(marker)) {
            //Remove it from the page
            marker.remove()
            //Remove it from markers map
            map.delete(key)
          }
        })
      }
    },
  },
}
