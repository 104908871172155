<template>
  <div class="w-full relative" id="container-historique">
    <RevelationMobileButton
      classes="hidden sm:block absolute z-10 bottom-0 px-6 py-3 border-promy-blue-200 rounded-none w-full font-semibold"
    />
    <div
      v-if="is_loading"
      class="bg-loader flex justify-center items-center absolute w-full h-full top-0 left-0 z-40"
    >
      <LoaderPromy
        classes="w-6"
        :size_bg_promy="0"
        :color="'#ffffff'"
        class="items-center justify-center flex"
      />
    </div>

    <div class="flex lg:flex-col-reverse">
      <div
        class="flex flex-col w-[34rem] md:w-full bg-promy-gray-50 md:bg-white p-12 lg:pt-8 md:px-0 text-promy-primary z-30"
      >
        <p class="font-bold text-xl sm:text-base mb-4 md:px-6">
          Historique de recherche
        </p>
        <p
          v-if="historiques.length && !isLoading"
          class="font-semibold py-4 md:px-6 md:border-b md:border-promy-gray-150"
        >
          Les plus récentes :
        </p>
        <div
          v-if="historiques.length && !isLoading"
          class="overflow-y-auto h-[63vh] -mr-4 pr-4 lg:h-[30vh] scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        >
          <div
            v-for="(adress, idx) in historiques"
            :key="idx"
            class="flex items-center w-full p-6 bg-white rounded-lg md:rounded-none hover:shadow-lg mb-3 md:mb-0 md:border-b md:border-promy-gray-150 md:border-r-0 border-r-[9px] border-r-white hover:border-r-promy-green-350 transition-all duration-100 cursor-pointer"
            @click="show(adress.uuid)"
          >
            <MarkerIcon color="#10AAAF" class="mr-6" />
            <div>
              <p class="font-bold md:text-sm">{{ adress.label }}</p>
              <p class="md:text-sm">{{ adress.context }}</p>
            </div>
            <div
              @click.stop=";(showModal = true), (adresse_to_remove = adress)"
              class="flex items-center justify-center bg-promy-gray-50 rounded-lg p-1 ml-auto"
            >
              <CloseIcon color="#81C2DE" class="w-5 h-5" />
            </div>
          </div>
        </div>
        <div class="py-12" v-else-if="!historiques.length && isLoading">
          <Spinner size="md_spinner" />
        </div>
        <div
          class="flex flex-col space-y-8 items-center flex-1 mt-28"
          v-else-if="!isLoading"
        >
          <img
            src="/images/empty-state-search.svg"
            class="w-40 h-auto"
            alt=""
            srcset=""
          />

          <div class="text-promy-gray-300">
            Vous n'avez pas encore d'historique de recherches
          </div>
        </div>
        <ul
          v-if="historiques && !isLoading"
          class="text-promy-green-350 mt-4 list-disc list-inside md:p-6"
        >
          <li>
            Vous pouvez encore consulter
            <span class="font-bold">
              {{ remaining_counter }}
              {{ remaining_counter === 1 ? 'adresse' : 'adresses' }}.
            </span>
          </li>
          <li v-if="remaining_counter <= 0">
            Vous pourrez rechercher 3 nouvelles adresses dès
            <span class="font-bold">le {{ counter_renewal_date }}</span>
          </li>
        </ul>
      </div>
      <mapHistorique ref="mapGUHistorique" @show="show"></mapHistorique>
    </div>

    <Popup
      v-if="showModal"
      @close="showModal = false"
      max_width_model="max-w-xl  sm:w-full"
    >
      <h2
        class="text-2xl font-bold py-2 font-main text-promy text-promy-green-350 sm:text-xl"
        slot="title"
      >
        Confirmation
      </h2>
      <p
        class="text-base font-normal text-promy-gray-250 mt-4 mb-8"
        slot="body"
      >
        Êtes-vous sûr de vouloir supprimer cette adresse ?
      </p>
      <div slot="buttons">
        <div class="flex space-x-4 justify-center">
          <ProButton
            class="h-[50px] space-x-1 sm:w-full w-1/3 !px-6"
            @click=";(showModal = false), (adresse_to_remove = null)"
            btn="primary"
            outline
          >
            <span> Annulez </span>
          </ProButton>
          <ProButton
            class="h-[50px] space-x-1 sm:w-full w-1/3 !px-6"
            @click="remove()"
            btn="primary"
          >
            <span> Continuez </span>
          </ProButton>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import risque from '@/mixins/risques'
import mapHistorique from './components/map'
export default {
  mixins: [mapbox, risque],
  components: {
    mapHistorique,
  },
  data() {
    return {
      is_loading: false,
      showModal: false,
      adresse_to_remove: null,
      error_message: null,
      is_first_loading_data: false,
    }
  },
  computed: {
    ...mapGetters({
      remaining_counter: 'address/getRemainingCounter',
      counter_renewal_date: 'address/getCounterRenewalDate',
      address: 'address/address',
      map: 'map/map',
      error_show_address: 'address/error_show_address',
      historiques: 'address/adresse_historique_by_bbox',
      isLoading: 'address/isHistoriqueLoading',
    }),
  },
  methods: {
    async show(uuid) {
      this.is_loading = true
      await this.$store.dispatch('address/showAddress', uuid)
      this.is_loading = false
      if (this.error_show_address) {
        this.toast('erreur', this.error_show_address, 'warning')
      } else {
        this.$router.push('/sections-gu/parcelle/' + uuid)
      }
    },
    async remove() {
      if (this.address && this.address.uuid === this.adresse_to_remove.uuid) {
        this.$store.commit('address/SET_ADDRESS', null)
        this.$router.push('/sections-gu/historique')
      }
      let status = await this.$store.dispatch(
        'address/removeAddress',
        this.adresse_to_remove.uuid,
      )
      status === 200
        ? this.toast(
            "Suppression d'adresse",
            "L'adresse a bien été supprimée.",
            'success',
          )
        : this.toast(
            "Suppression d'adresse",
            "L'adresse n'est pas supprimée.",
            'error',
          )
      this.showModal = false
      this.adresse_to_remove = null
      this.$refs.mapGUHistorique.addSourceHistoriquesInMap()
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy()
    next()
  },
}
</script>

<style scoped>
.bg-loader {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
