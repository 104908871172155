function searchForArray(haystack, needle) {
  var i, j, current
  for (i = 0; i < haystack.length; ++i) {
    if (needle.length === haystack[i].length) {
      current = haystack[i]
      for (j = 0; j < needle.length && needle[j] === current[j]; ++j);
      if (j === needle.length) return true
    }
  }
  return false
}

export { searchForArray }
